<template>
	<div class="w-full h-full flex flex-col notification">
		<div class="flex-shrink-0">
			<div class="text-right px-4 pt-4 block sm:hidden">
				<button
					class="inline-block focus:outline-none"
					@click="$emit('close')"
				>
					<UIcon
						name="uil:multiply"
						size="16"
					/>
				</button>
			</div>
			<div class="px-4 flex items-center justify-between h-16">
				<span class="font-semibold text-base">
					{{ $t('Notificaciones') }}
				</span>

				<div class="flex space-x-2">
					<!--          <SettingsNotifications />-->
					<FilterNotifications
						v-if="notifications.length > 0 || filterActive"
						@change="changeFilters"
					/>
				</div>
			</div>
		</div>

		<div
			ref="notifications"
			class="overflow-y-auto flex-1"
		>
			<ul v-if="notifications.length">
				<li
					v-for="(notification, index) in notifications"
					:key="index"
				>
					<ItemNotification
						:notification="notification"
						class="mb-1"
						@click="notificationInteraction"
					/>
				</li>
			</ul>

			<ul v-if="loadingMore">
				<li>
					<SkeletonNotification />
				</li>
			</ul>

			<div
				v-if="notifications.length === 0 && !loadingMore"
				class="flex items-center justify-center flex-1 h-full"
			>
				<EmptyNotifications />
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useNotificationsStore } from '~/store/notifications'
import SkeletonNotification from '~/components/notifications/SkeletonNotification.vue'
import FilterNotifications from '~/components/notifications/FilterNotifications.vue'
import EmptyNotifications from '~/components/notifications/EmptyNotifications.vue'
import ItemNotification from '~/components/notifications/ItemNotification.vue'
// import SettingsNotifications from '~/components/notifications/SettingsNotifications.vue'

export default {
	name: 'ListNotifications',
	components: {
		// SettingsNotifications,
		SkeletonNotification,
		FilterNotifications,
		EmptyNotifications,
		ItemNotification,
	},
	data: () => ({
		loadingMore: false,
		filterActive: false,
	}),
	computed: {
		...mapState(useNotificationsStore, ['notifications', 'pagination', 'filters']),
	},
	mounted() {
		this.$refs.notifications.addEventListener('scroll', this.onScroll)
		if (this.notifications.length) {
			this.filterActive = true
		}
	},
	methods: {
		...mapActions(useNotificationsStore, ['fetchNotifications', 'SET_STATE_NOTIFICATIONS']),
		async getMoreNotifications(newParams = {}) {
			this.loadingMore = true
			const params = {
				page: this.pagination.current_page + 1,
				...this.filters,
				...newParams,
			}
			await this.fetchNotifications(params)
			this.loadingMore = false
		},
		onScroll() {
			const { scrollTop, clientHeight, scrollHeight } = this.$refs.notifications
			const scrollTopBox = Math.round(scrollTop + clientHeight)

			if (scrollTopBox >= scrollHeight) {
				this.getScrollNotifications()
			}

			this.notificationInteraction()
		},
		async getScrollNotifications() {
			const { current_page, last_page } = this.pagination
			if (this.loadingMore || current_page === last_page) return
			await this.getMoreNotifications()
		},
		setIsFilter() {
			if (Object.values(this.filters).filter((item) => item).length > 0) {
				this.filterActive = true
			}
		},
		changeFilters(filters) {
			this.loadingMore = true

			this.SET_STATE_NOTIFICATIONS({ type: 'filters', value: filters })
			this.setIsFilter()

			this.SET_STATE_NOTIFICATIONS({ type: 'notifications', value: [] })
			this.SET_STATE_NOTIFICATIONS({
				type: 'pagination',
				value: { ...this.pagination, current_page: 1 },
			})
			this.getMoreNotifications({ page: 1 })
		},
		notificationInteraction() {
			this.$emit('interaction', true)
		},
	},
}
</script>

<style lang="postcss">
.notification {
	.box-shadow {
		box-shadow:
			0px 0px 2px rgba(42, 30, 79, 0.25),
			0px 0px 4px rgba(42, 30, 79, 0.08),
			0px 4px 32px rgba(42, 30, 79, 0.16);
	}
}
</style>
